<template>
  <div class="home">
    <h1 class="header">{{ video.title }}</h1>
    <h2 class="sub-header">{{ video.sub_title }}</h2>
    <!-- <iframe 
      src="https://www.youtube.com/embed/i69D-a-pgAI?si=Ixb43sX8tm-7Ryul"
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe> -->
    <video v-if="video.source === 'file'" autoplay muted loop controls controlslist class="video">
      <source :src="video.videoUrl" class="video">
    </video>
    <iframe 
      v-else-if="video.source === 'youtube'" 
      :src="video.link + '?&loop=1&autoplay=1&mute=1'" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
    <iframe 
      v-else-if="video.source === 'vimeo'"
      :src="video.link" 
      frameborder="0" 
      allow="autoplay; fullscreen; picture-in-picture" 
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
import sanity from "../client";

export default {
 data() {
    return {
      videosResponse: null
    }
  },
  async mounted() {    
    const query = `*[_type == 'homepage' && !(_id in path("drafts.**"))]{"videoUrl": file.asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    this.videosResponse = response
  },
  computed: {
    video() {
      return this.videosResponse && this.videosResponse.length ? this.videosResponse[0] : {title: "", sub_title: ""}
    }
  }
}
</script>

<style scoped>
.home-image {
  display: block;
  width: 100vh;
  max-height: 100vh;
  margin: auto;
}
.home {
  text-align: center;
  margin-right: 150px;
}
iframe {
  width: 700px;
  height: 500px;
  object-fit: cover;
}
.header {
  margin-bottom: 5px;
}
.sub-header {
  margin-top: 5px;
}
@media screen and (max-width: 600px) {
  iframe {
    width: 90vw;
    object-fit: cover;
  }
  .home {
    margin-right: 0px;
  }

}
</style>
