<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <div :style="darkMode ? {color: 'blue', backgroundColor: 'black'} : {color: 'blue', backgroundColor: 'white'}" class="app-container">
    <Index @toggleDark="darkMode = !darkMode" class="index"/>
  </div>
  <!-- <router-view/> -->
</template>

<script>
// import Index from '@views/Index.vue'
import Index from './views/Index.vue'
// import { useSanityClient } from 'vue-sanity'

export default {
  components: {
    Index
  },
  data() {
    return {
      darkMode: false
    }
  }
  // setup() {
  //   useSanityClient({
  //     projectId: '1bk1c2xl',
  //     dataset: 'production',
  //     useCdn: process.env.NODE_ENV === 'production',
  //   })
  // },
}
</script>


<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: career, 'Courier New', Courier, monospace;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: blue; */
  /* background-color: #1c1b1c; */
}

/* @font-face {
    font-family: comingsoon;
    src: url(./assets/ComingSoon.ttf);
} */

@font-face {
    font-family: career;
    src: url(./assets/Career.ttf);
}

/* #nav {
  padding: 30px;
} */

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.index {
  height: 100vh;
}
body {
  margin: 0px;
  /* background-color: #1c1b1c; */

  /* background-image: url('./assets/55800c34ea440393432720ed75823915.gif'); */
  /* background-image: url('./assets/tumblr_1f1c8361f5a96af671fa6f19923a3b93_51f22f99_500.gif');
    background-size: cover; */
    
    
/*     
    height: 100vh;
    padding:0;
    margin:0; */
}
.app-container {
  height: 100vh;
  overflow-y: scroll;
}
</style>
